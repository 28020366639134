import { Module } from 'vuex'
import router from '@/router'
import { ILoginState } from './type'
import { IRootState } from '../type'
import { IAccount } from '@/service/login/type'
import {
  accountLoginRequest,
  requestUserInfoById,
  requestUserMenusByRoleId,
  getUserInfo,
  querySiteConfig
} from '@/service/login/login'
import localCache from '@/utils/cache'
import { getCookie } from '@/utils/cookie'
import { mapMenusToPermission, mapMenusToRoutes } from '@/utils/map-menus'
import { staticMenuRoute } from '@/router/staticMenuRoute'
import Cookies from 'js-cookie'

const loginModule: Module<ILoginState, IRootState> = {
  namespaced: true,
  state() {
    return {
      token: '',
      userInfo: {},
      userMenus: [],
      permissions: [],
      siteInfo: {
        siteName: '',
        siteLogo: ''
      }
    }
  },
  getters: {},
  mutations: {
    changeToken(state, token: string) {
      state.token = token
    },
    changeUserInfo(state, userInfo: any) {
      state.userInfo = userInfo
    },
    changeUserMenus(state, userMenus: any[]) {
      state.userMenus = userMenus

      console.log('注册路由')

      // userMenus => routes
      const routes = mapMenusToRoutes(userMenus)

      //将routes => router.main.children
      routes.forEach((route) => {
        router.addRoute('main', route)
      })

      // 获取用户按钮的权限
      const permissions = mapMenusToPermission(userMenus)
      state.permissions = permissions
    },
    changeSiteInfo(state, { siteName, siteLogo }: { siteName: string; siteLogo: string }) {
      state.siteInfo.siteName = siteName
      state.siteInfo.siteLogo = siteLogo
    }
  },
  actions: {
    async accountLoginAction({ commit }, payload: IAccount) {
      // 1.实现登录逻辑
      await accountLoginRequest(payload)
      const token = 'manger'
      commit('changeToken', token)
      localCache.setCache('token', token)

      // const { id, token } = loginResult.data
      // console.log(token)

      // 2.请求用户信息
      // const userInfoResult = await requestUserInfoById(id)
      // const userInfo = userInfoResult.data
      const userInfo = { name: 'admin' }
      commit('changeUserInfo', userInfo)
      localCache.setCache('userInfo', userInfo)

      // 3.请求用户菜单
      // const userMenusResult = await requestUserMenusByRoleId(userInfo.role.id)
      // const userMenus = userMenusResult.data
      const userMenus = staticMenuRoute
      commit('changeUserMenus', userMenus)
      localCache.setCache('userMenus', userMenus)
      // 4.跳到首页
      router.push('/main')
    },
    // 存储用户信息
    async requestUserInfo({ commit }) {
      const { httpCode, data: userInfo } = await getUserInfo()
      if (httpCode === 200) {
        commit('changeUserInfo', userInfo)
        localCache.setCache('userInfo', userInfo)
      }
    },
    // 存储站点信息
    async requestSiteInfo({ commit }) {
      const { httpCode, data } = await querySiteConfig()
      if (httpCode === 200) {
        commit('changeSiteInfo', {
          siteName: data?.site_name?.value,
          siteLogo: data?.site_logo?.value
        })
        localCache.setCache('siteInfo', {
          siteName: data?.site_name?.value,
          siteLogo: data?.site_logo?.value
        })
      }
    },
    //当页面刷新时，重新给vuex赋值
    loadLocalLogin({ commit }) {
      const token = localCache.getCache('token')
      if (token) commit('changeToken', token)

      const userInfo = localCache.getCache('userInfo')
      if (userInfo) commit('changeUserInfo', userInfo)

      const userMenus = localCache.getCache('userMenus')
      if (userMenus) commit('changeUserMenus', userMenus)

      const siteInfo = localCache.getCache('siteInfo')
      if (siteInfo)
        commit('changeSiteInfo', { siteName: siteInfo?.siteName, siteLogo: siteInfo?.siteLogo })
    }
  }
}

export default loginModule
