export const staticMenuRoute = [
  {
    id: 41,
    name: '工程管理',
    type: 2,
    url: '/main/management',
    icon: 'engineer-mana.png',
    sort: 1,
    children: null
  },
  {
    id: 42,
    name: '协同设计',
    type: 2,
    url: '',
    icon: 'coll-design.png',
    sort: 2,
    children: null
  },
  {
    id: 43,
    name: '设计工具',
    type: 2,
    url: '',
    icon: 'design-tool.png',
    sort: 2,
    children: null
  },
  // {
  //   id: 43,
  //   name: '本地资源',
  //   type: 2,
  //   url: '/main/resources',
  //   icon: 'local-resources.png',
  //   sort: 3,
  //   children: [
  //     {
  //       id: 431,
  //       name: '本地资源',
  //       type: 3,
  //       url: '/main/resources/project-file',
  //       icon: '',
  //       sort: 1
  //     },
  //     {
  //       id: 432,
  //       name: '工具软件',
  //       type: 3,
  //       url: '/main/resources/tool-software',
  //       icon: '',
  //       sort: 2
  //     },
  //     {
  //       id: 433,
  //       name: '路径设置',
  //       type: 3,
  //       url: '/main/resources/path-setting',
  //       icon: '',
  //       sort: 3
  //     }
  //   ]
  // },
  {
    id: 44,
    name: '系统设置',
    type: 2,
    url: '/main/system-setting',
    icon: 'system-settings.png',
    sort: 4,
    children: null
  }
  // {
  //   id: 45,
  //   name: '帮助',
  //   type: 2,
  //   url: '/main/footprint',
  //   icon: 'help.png',
  //   sort: 4,
  //   children: null
  // }
]
