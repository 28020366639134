const resources = () => import('@/views/main/resources/resources.vue')
const projectFile = () => import('@/views/main/resources/project-file/project-file.vue')
const toolSoftware = () => import('@/views/main/resources/tool-software/tool-software.vue')
const pathSetting = () => import('@/views/main/resources/path-setting/path-setting.vue')

export default {
  path: '/main/resources',
  name: 'resources',
  component: resources,
  children: [
    {
      path: '',
      redirect: '/main/resources/project-file'
    },
    {
      path: '/main/resources/project-file',
      name: 'project-file',
      component: projectFile
    },
    {
      path: '/main/resources/tool-software',
      name: 'tool-software',
      component: toolSoftware
    },
    {
      path: '/main/resources/path-setting',
      name: 'path-setting',
      component: pathSetting
    }
  ]
}
