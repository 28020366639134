import localCache from '@/utils/cache'
import { ElMessage } from 'element-plus'
import store from '@/store'
// 客户端指令调用工具

type CallbackClientForJSResult = {
  data: any
  status: boolean
  msg: string
}

// js调用c++
export function callbackClientForJS(funcName: string, data: any = {}, isMainLoading = false) {
  const params = { funcName, data }
  console.log(funcName + ':', '传参:', data)
  if ((window as any).NimCefWebInstance) {
    return new Promise<any>((resolve, reject) => {
      isMainLoading && store.commit('setMainLoading', true)
      ;(window as any).NimCefWebInstance.call(
        'callbackClientForJS',
        params,
        (error: any, result: CallbackClientForJSResult) => {
          if (result.status) {
            console.log(funcName + ':', '成功响应:', result)
            isMainLoading && store.commit('setMainLoading', false)
            resolve(result)
          } else {
            // console.log(funcName + ':', '失败响应:', result)
            isMainLoading && store.commit('setMainLoading', false)
            ElMessage.error(result.msg)
            reject(result)
          }
        }
      )
    })
  }
}

// 客户端唤起本地网页
export function clientCallLocalWebByUrl(webRoute = '', params?: { [key: string]: any }) {
  const token = localCache.getCache('token')
  let url = `/#/clientLogin?token=${token}&pat=${webRoute}`
  params &&
    Object.keys(params).forEach((key) => {
      url += `&${key}=${params[key]}`
    })
  console.log(url)
  return callbackClientForJS('handleGoUrl', { url })
}
