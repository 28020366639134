const GB = 1024 * 1024 * 1024
const MB = 1024 * 1024
const KB = 1024
// 处理文件大小转换显示
export function handleFileSize(fileSize: string) {
  const size = Number(fileSize)
  if (size > GB) {
    return (size / GB).toFixed(2) + 'GB'
  } else if (size > MB) {
    return (size / MB).toFixed(2) + 'MB'
  } else if (size > KB) {
    return (size / KB).toFixed(2) + 'KB'
  } else {
    return size + 'B'
  }
}
