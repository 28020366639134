import { App } from 'vue'
import { formatUtcString } from '@/utils/date-format'
import { handleFileSize } from '@/utils/filesize-format'
import { handleTextOverflowEllipsis } from '@/utils/text-overflow-ellipsis'

export default function registerProperties(app: App) {
  app.config.globalProperties.$filters = {
    // 格式化时间
    formatTime(value: string, format?: string) {
      return formatUtcString(value, format)
    },
    // 处理文本
    textOverflowEllipsis(text = '', limitedNum = 10) {
      return handleTextOverflowEllipsis(text, limitedNum)
    },
    // 格式化文件大小
    formatFileSize(value: string) {
      return handleFileSize(value)
    }
  }
}
