import { Module } from 'vuex'
import { IRootState } from '../type'
import { IWebSocketState } from './type'

const websocketModule: Module<IWebSocketState, IRootState> = {
  namespaced: true,
  state() {
    return {
      data: {},
      webSocket: null
    }
  },
  getters: {},
  mutations: {
    //websocket初始化
    initWebsocket(state, payload) {
      const { websocketUrl, queryRequest } = payload
      const ws = new WebSocket(
        // 此处填写你要连接的ws地址
        websocketUrl
      )
      //建立连接
      ws.onopen = function () {
        /*
         * 连接成功
         * */
        console.log('通讯开始')
        // 发送心跳防止ws协议自动断联
        setInterval(() => {
          state.webSocket.send('1')
        }, 1000 * 60)
      }
      //接收服务端消息
      ws.onmessage = function (e: any) {
        /*
         * 收到消息时回调函数
         * */
        console.log('收到的数据：', e.data)
        // 如果数据对象为字符串，可转换为对象格式
        const data = JSON.parse(e.data)
        state.data = e.data
        console.log(data)
        const { type } = data
        if (type === '1') {
          console.log('刷新数据')
          queryRequest({ pageNum: 1, pageSize: 10 }) //刷新数据
        }
      }
      ws.onerror = function (err: any) {
        console.log(err)
        /*
         * 通讯异常
         * */
        console.log('通讯异常')
      }
      ws.close = function () {
        /*
         * 关闭连接时回调函数
         * */
        console.log('连接已断开')
      }
      state.webSocket = ws
    }
  },
  actions: {}
}

export default websocketModule
