import hyRequest from '../index'
import { IAccount, ILoginResult, ILoginSiteInfo } from './type'
import { IDataType } from '../type'

enum LoginAPI {
  AccountLogin = '/login',
  LoginUserInfo = '/users/',
  UserMenus = '/role/'
}

// 登录
export function accountLoginRequest(account: IAccount) {
  return hyRequest.post<IDataType<ILoginResult>>({
    url: '/sys/api/v1/login',
    data: account
  })
}

// 获取用户信息
export function getUserInfo() {
  return hyRequest.get<IDataType<ILoginResult>>({
    url: '/sys/api/v1/user/read/current'
  })
}

// 获取站点信息
export function querySiteConfig() {
  return hyRequest.get<IDataType<ILoginSiteInfo>>({
    url: '/sys/api/v1/siteConfig/list'
  })
}

export function requestUserInfoById(id: number) {
  return hyRequest.get<IDataType>({
    url: LoginAPI.LoginUserInfo + id,
    showLoading: false
  })
}

export function requestUserMenusByRoleId(id: number) {
  return hyRequest.get<IDataType>({
    url: LoginAPI.UserMenus + id + '/menu',
    showLoading: false
  })
}
