import { App } from 'vue'

import {
  Search,
  UserFilled,
  Iphone,
  Monitor,
  Setting,
  Goods,
  Expand,
  Fold,
  CircleClose,
  RefreshLeft,
  ChatLineRound,
  Warning,
  Bell,
  Cpu,
  SetUp,
  CopyDocument,
  Menu,
  CaretRight,
  CircleCheck,
  ArrowLeft,
  ArrowDown,
  Check,
  Plus,
  Files,
  Operation,
  HelpFilled,
  FolderOpened,
  View,
  UploadFilled,
  TakeawayBox,
  Minus,
  Close,
  Document,
  FullScreen,
  MoreFilled,
  MostlyCloudy,
  Refresh,
  CaretBottom,
  Delete,
  TopRight,
  BottomRight,
  Finished,
  Timer,
  DArrowRight,
  DArrowLeft,
  Download
} from '@element-plus/icons-vue'

const components = [
  Search,
  UserFilled,
  Iphone,
  Monitor,
  Setting,
  Goods,
  Expand,
  Fold,
  CircleClose,
  RefreshLeft,
  ChatLineRound,
  Warning,
  Bell,
  Cpu,
  SetUp,
  CopyDocument,
  Menu,
  CaretRight,
  CircleCheck,
  ArrowLeft,
  ArrowDown,
  Check,
  Plus,
  Files,
  Operation,
  HelpFilled,
  FolderOpened,
  View,
  UploadFilled,
  TakeawayBox,
  Minus,
  Close,
  Document,
  FullScreen,
  MoreFilled,
  MostlyCloudy,
  Refresh,
  CaretBottom,
  Delete,
  TopRight,
  BottomRight,
  Finished,
  Timer,
  DArrowRight,
  DArrowLeft,
  Download
]

export default function (app: App): void {
  for (const component of components) {
    app.component(component.name, component)
  }
}
