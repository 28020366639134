import axios from 'axios'
import type { AxiosInstance } from 'axios'

import { HYRequestInterceptors, HYRequestCinfig } from './type'

const DEAFULT_LOADING = true
axios.defaults.withCredentials = false
class HYRequest {
  instance: AxiosInstance //类HYRequest 拥有instance，interceptors实例
  interceptors?: HYRequestInterceptors
  loading?: any
  showLoading: boolean

  constructor(config: HYRequestCinfig) {
    //创建axios实例
    this.instance = axios.create(config)

    //保存传过来的基本信息
    this.interceptors = config.interceptors
    this.showLoading = config.showLoading ?? DEAFULT_LOADING

    // 使用拦截器
    // 1.从config中取出的拦截器是对应的实例的拦截器
    this.instance.interceptors.request.use(
      this.interceptors?.requestInterceptor,
      this.interceptors?.requestInterceptorCatch
    )
    this.instance.interceptors.response.use(
      this.interceptors?.responseInterceptor,
      this.interceptors?.responseInterceptorCatch
    )
  }

  request<T = any>(config: HYRequestCinfig<T>): Promise<T> {
    return new Promise((resolve, reject) => {
      // 1.判断是否需要显示loading
      if (config.showLoading === false) {
        this.showLoading = false
      }
      this.instance
        .request<any, T>(config)
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
        .finally(() => {
          this.showLoading = DEAFULT_LOADING
        })
    })
  }

  get<T = any>(config: HYRequestCinfig<T>): Promise<T> {
    return this.request<T>({
      ...config,
      method: 'GET',
      withCredentials: false
    })
  }
  post<T = any>(config: HYRequestCinfig<T>): Promise<T> {
    return this.request<T>({
      ...config,
      method: 'POST',
      withCredentials: false
    })
  }
  delete<T = any>(config: HYRequestCinfig<T>): Promise<T> {
    return this.request<T>({
      ...config,
      method: 'DELETE',
      withCredentials: false
    })
  }
  patch<T = any>(config: HYRequestCinfig<T>): Promise<T> {
    return this.request<T>({
      ...config,
      method: 'PATCH',
      withCredentials: false
    })
  }
}

export default HYRequest
