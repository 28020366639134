import { App, createApp } from 'vue'
import Loading from './loading.vue'
export default function registerLoading(app: App) {
  app.directive('loading', {
    mounted(el, binding, vnode) {
      const app = createApp(Loading)
      const instance = app.mount(document.createElement('div')) //创建loading组件实例
      el.instance = instance
      el.style.position = 'relative'
      if (binding.value) {
        appendEl(el)
      }
    },
    updated(el, binding) {
      if (binding.value !== binding.oldValue) {
        binding.value ? appendEl(el) : removeEl(el)
      }
    }
  })
  const appendEl = (el: { appendChild: (arg0: any) => void; instance: { $el: any } }) => {
    el.appendChild(el.instance.$el)
  }
  const removeEl = (el: { removeChild: (arg0: any) => void; instance: { $el: any } }) => {
    el.removeChild(el.instance.$el)
  }
}
