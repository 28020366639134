import Cookies from 'js-cookie'

export const setCookie = (key: string, value: any) => {
  Cookies.set(key, value)
}

export const getCookie = (key: string) => {
  const value = Cookies.get(key)
  return value
}

export const clearCookie = (key: string) => {
  Cookies.remove(key)
}
