import { createRouter, createWebHashHistory } from 'vue-router'
import type { RouteRecordRaw } from 'vue-router'
import localCache from '@/utils/cache'
import { firstMenu } from '@/utils/map-menus'

import customForm from './main/customForm/index' // 自定义表单

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    redirect: '/main'
  },
  // {
  //   path: '/login',
  //   name: 'login',
  //   component: () => import('@/views/login/login.vue')
  // },
  {
    path: '/main',
    name: 'main',
    component: () => import('@/views/main/main.vue'),
    children: []
  },
  {
    path: '/no-permission',
    name: 'no-permission',
    component: () => import('@/views/no-permission/no-permission.vue')
  },
  customForm,
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: () => import('@/views/not-found/not-found.vue')
  }
]

const router = createRouter({
  routes,
  history: createWebHashHistory()
})

router.beforeEach((to) => {
  // if (to.path !== '/no-permission') {
  //   const token = localCache.getCache('token')
  //   if (!token) {
  //     return '/no-permission'
  //   }
  // }

  if (to.path === '/main') {
    return firstMenu.url
  }
})

export default router
