import { createApp } from 'vue'
import App from './App.vue'
import { globalRegister } from './global'
import 'normalize.css'

import router from './router'
import { setupStore } from './store'
import store from './store'
import registerDirectives from './directives'
import uploader from 'vue-simple-uploader'
import 'vue-simple-uploader/dist/style.css'
import { Icon } from '@iconify/vue'
import localCache from '@/utils/cache'
import { staticMenuRoute } from './router/staticMenuRoute'
import './assets/css/index.less'

import '@/utils/initJq.js'

setupStore() // 当页面刷新时，重新给vuex赋值

// 存储菜单数据
const userMenus = staticMenuRoute
store.commit('login/changeUserMenus', userMenus)
localCache.setCache('userMenus', userMenus)

const app = createApp(App)
app.use(router)
app.use(store)
app.use(uploader)
app.component('Icon', Icon)
app.use(globalRegister) //注册全局组件
registerDirectives(app) //注册指令

if (window.location.href.split('token').length > 1) {
  const token = window.location.href.split('token')[1].split('=')[1].split('#')[0]
  if (token) {
    localCache.setCache('token', token)
    // 存储用户信息
    store.dispatch('login/requestUserInfo')
    // 获取站点信息
    store.dispatch('login/requestSiteInfo')
  }
}

app.mount('#app')

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $filters: any
  }
}
