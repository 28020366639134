const customForm = () => import('@/views/main/customForm/index.vue')
const fileReview = () => import('@/views/main/customForm/fileReview.vue')

export default {
  path: '/customForm',
  name: 'customForm',
  component: customForm,
  children: [
    {
      path: 'fileReview',
      name: 'fileReview',
      component: fileReview
    }
  ]
}
