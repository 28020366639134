import HYRequest from './request'
import { BASE_URL, TIME_OUT } from './request/config'
import localCache from '@/utils/cache'
import { ElMessage, ElNotification } from 'element-plus'
import router from '@/router'
import { clearCookie, getCookie } from '@/utils/cookie'
import { callbackClientForJS } from '@/utils/callback-client'

let loginStatus = true // 记录登录状态是否过期

const hyRequest = new HYRequest({
  baseURL: BASE_URL,
  timeout: TIME_OUT,
  withCredentials: false,
  interceptors: {
    requestInterceptor: (config) => {
      // 携带token的拦截
      const token = localCache.getCache('token')
      if (token && config.headers) {
        config.headers['cToken'] = token
      }
      // 清空cookie
      clearCookie('deehowEDMSID')
      clearCookie('JSESSIONID')
      // 清空Cookie值
      return config
    },
    requestInterceptorCatch: (error) => {
      return Promise.reject(error)
    },
    responseInterceptor: (res) => {
      const { responseType } = res.request
      // 删除Set-Cookie字段
      delete res.headers['set-cookie']
      // 或者将Set-Cookie字段设置为空字符串
      res.headers['set-cookie'] = undefined

      if (responseType === 'arraybuffer' || responseType === 'blob') {
        // 处理文件流
        const fileName = decodeURI(res.headers['filename'] ?? '')
        return {
          fileName,
          blob: res.data
        }
      }

      if (res?.headers['content-type']?.includes('octet-stream')) {
        // 处理返回二进制文件数据
        return res.data
      }

      // 处理返回体为空
      if (res.data === '') {
        return res.data
      }

      const { httpCode, msg } = res.data
      if (httpCode !== 200) {
        switch (httpCode) {
          case 401:
            if (loginStatus) {
              ElNotification({
                title: 'Error',
                message: '您登录凭证失效,请重新登录~',
                type: 'error'
              })
              loginStatus = false

              // 退出登录
              return new Promise((reslve, reject) => {
                setTimeout(() => {
                  const result = callbackClientForJS('handleExit')
                  reslve(result)
                }, 3000)
              }).then((result) => {
                if (result) {
                  localCache.deleteCache('token')
                  localCache.deleteCache('userInfo')
                  router.push('/main')
                  router.push('/no-permission')
                }
              })
            }
            break

          case 400:
            ElMessage.error(msg)
            return Promise.reject(res)
          default:
            ElNotification({
              title: httpCode >= 500 ? 'Error' : 'Warning',
              message: msg,
              type: httpCode >= 500 ? 'error' : 'warning'
            })
            return Promise.reject(res)
        }
        return Promise.reject(res)
      } else {
        return res.data
      }
    },
    responseInterceptorCatch: (error) => {
      ElNotification({
        title: 'Error',
        message: error.message,
        type: 'error'
      })
      return Promise.reject(error)
    }
  }
})

export default hyRequest
