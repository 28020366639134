import { createStore, useStore as useVuexStore } from 'vuex'
import { Store } from 'element-plus/es/components/table/src/store'

import login from './login/login'
import websocket from './websocket/websocket'

import { IRootState, IStoreType } from './type'

const store = createStore<IRootState>({
  state: () => {
    return {
      name: 'coder why',
      mainLoading: false
    }
  },
  mutations: {
    setMainLoading(state, value: boolean) {
      state.mainLoading = value
    }
  },
  actions: {},
  modules: {
    login,
    websocket
  }
})

export function setupStore() {
  store.dispatch('login/loadLocalLogin')
}

export function useStore(): Store<IStoreType> {
  return useVuexStore()
}

export default store
