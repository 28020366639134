import { IBreadcrumb } from '@/base-ui/breadcrumb'
import { RouteRecordRaw } from 'vue-router'
let firstMenu: any = null

export function mapMenusToRoutes(userMenus: any[]): RouteRecordRaw[] {
  const routes: RouteRecordRaw[] = []
  //1. 先加载默认所有的routes
  const allRoutes: RouteRecordRaw[] = []

  //require.context(directory,useSubdirectories,regExp) webpack的APi，用来在组件内引入多个组件,
  //directory:表示检索的目录
  //useSubdirectories：表示是否检索子文件夹
  //regExp:匹配文件的正则表达式,一般是文件名
  const routeFiles = require.context('../router/main', true, /\.ts/)
  routeFiles.keys().forEach((key) => {
    const route = require('../router/main' + key.split('.')[1])
    allRoutes.push(route.default)
  })

  const _recurseGetRoute = (menus: any[]) => {
    for (const menu of menus) {
      if (menu.type === 2) {
        const route = allRoutes.find((route) => route.path === menu.url)
        if (route) routes.push(route)
        if (!firstMenu) firstMenu = menu //记录动态菜单的第一个菜单
      } else {
        _recurseGetRoute(menu.children)
      }
    }
  }
  _recurseGetRoute(userMenus)

  return routes
}

export function pathMapBreadcrumds(userMenus: any[], currentPath: string): IBreadcrumb[] {
  const breadcrumbs: IBreadcrumb[] = []
  pathToMenu(userMenus, currentPath, breadcrumbs)
  return breadcrumbs
}

export function pathToMenu(
  userMenus: any[],
  currentPath: string,
  breadcrumbs?: IBreadcrumb[]
): any {
  for (const menu of userMenus) {
    if (menu.type === 1) {
      const findMenu = pathToMenu(menu.children, currentPath)
      if (findMenu) {
        breadcrumbs?.push({ name: menu.name })
        breadcrumbs?.push({ name: findMenu.name })
        return findMenu
      }
    } else if (menu.type === 2) {
      if (menu.url === currentPath) {
        breadcrumbs?.push({ name: menu.name })
        return menu
      } else {
        if (menu.children) {
          const findMenu = pathToMenu(menu.children, currentPath)
          if (findMenu) {
            breadcrumbs?.push({ name: menu.name })
            breadcrumbs?.push({ name: findMenu.name })
            return findMenu
          }
        }
      }
    } else if (menu.type === 3 && menu.url === currentPath) {
      breadcrumbs?.push({ name: menu.name })
      return menu
    }
  }
}

export { firstMenu }

export function mapMenusToPermission(userMenus: any[]) {
  const permissions: string[] = []

  const _recurseGetPermission = (menus: any[]) => {
    for (const menu of menus) {
      if (menu.type === 1 || menu.type === 2) {
        _recurseGetPermission(menu.children ?? [])
      } else {
        permissions.push(menu.permission)
      }
    }
  }
  _recurseGetPermission(userMenus)

  return permissions
}

export function menuMapLeafKeys(menuList: any[]) {
  const leftKeys: number[] = []

  const _recurseGetLeaf = (menuList: any[]) => {
    for (const menu of menuList) {
      if (menu.children) {
        _recurseGetLeaf(menu.children)
      } else {
        leftKeys.push(menu.id)
      }
    }
  }

  _recurseGetLeaf(menuList)

  return leftKeys
}
