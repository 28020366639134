import { App } from 'vue'

import registerElement from './register-element'
import registerElementIcon from './register-elementIcon'
import registerProperties from './register-properties'

export function globalRegister(app: App): void {
  // registerElement(app)
  app.use(registerElement) //注册elementui组件
  app.use(registerElementIcon) //注册elementIcon
  app.use(registerProperties) //注册全局对象

  // 全局变量
  app.config.globalProperties.$rowClass = {
    background: '#EBEDF0',
    color: '#000000',
    padding: '4px',
    height: '45px',
    fontSize: '16px',
    fontWeight: '600'
  }

  app.config.globalProperties.$rowStyle = { height: '40px' }
  app.config.globalProperties.$cellStyle = { padding: '2px 0' }
}
